@import '../../assets/scss/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.under-construction {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-color;
  overflow: hidden;
  text-align: center;

  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h1,
  h3 {
    color: $white-color;
  }

  h1 {
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 48px;
    }
  }

  h3 {
    margin-bottom: 80px;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  &__hello {
    font-weight: $font-weight-regular;
  }

  &__roles {
    opacity: 0;

    span:not(:last-of-type) {
      margin-right: 15px;

      &::after {
        content: '|';
        margin-left: 15px;
      }
    }

    &--shown {
      opacity: 0.8;
      transition: opacity 0.5s;
    }
  }

  &__coming-soon {
    position: absolute;
    bottom: -100px;
    right: 10%;
    opacity: 0;

    &--shown {
      opacity: 1;
      bottom: 10%;
      transition: bottom 0.5s;

      @include media-breakpoint-down(sm) {
        bottom: 0;
      }
    }
  }

  &__post-it {
    text-decoration: none;
    color: $primary-color;
    background: $secondary-color;
    display: block;
    cursor: pointer;
    height: 200px;
    width: 200px;
    padding: 14px;
    text-align: center;
    transform: rotate(4deg);
    box-shadow: 4px 4px 7px rgba(107, 107, 107, 0.7);
    position: relative;
    top: 5px;
    transition: transform 0.25s;

    @include media-breakpoint-down(md) {
      transform: rotate(4deg) scale(0.85);
    }

    @include media-breakpoint-down(sm) {
      transform: rotate(4deg) scale(0.75);
    }

    &:hover,
    &:focus {
      transform: scale(1.25);
      z-index: 5;
    }

    &__title {
      font-size: 20px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    &__text {
      margin-bottom: 10px;
      font-size: 14px;
      font-style: italic;
    }

    &__link {
      color: $primary-color;
      span {
        font-weight: $font-weight-bold;
      }

      &:hover {
        color: $primary-color;

        i {
          margin-right: 15px;
          transition: margin-right 0.25s;
        }
      }
    }

    i {
      margin-right: 8px;
    }
  }
}
