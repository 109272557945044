@import './variables';
@import '../../../node_modules/bootstrap/scss/mixins/grid';

* {
  box-sizing: border-box;
}

body {
  font: $font-weight-light 15px/1.6 $body-font-family;
  color: $body-color;
  background-color: $white-color;
  margin: 0;

  a {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $secondary-color;
    }
  }
}

.wrapper {
  overflow: hidden;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  &::before,
  &::after {
    display: table;
    content: ' ';
    box-sizing: border-box;
  }

  &::after {
    clear: both;
  }

  @media (max-width: 767px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
}

.row {
  @include make-row();
}
