@import '../../../assets/scss/variables';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: $primary-color;
  font-family: $body-font-family;
  font-size: 13px;
  font-weight: $font-weight-regular;
  color: $white-color;

  &__text {
    margin-bottom: 10px;
  }

  &__links {
    a {
      color: $white-color;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        color: $secondary-color;
      }
    }

    a:not(:last-of-type) {
      margin-right: 10px;

      &::after {
        content: '|';
        margin-left: 10px;
      }
    }
  }
}
