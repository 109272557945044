a,
.btn,
.navbar,
.form-control,
.portfolio-item-description {
  -webkit-transition: all 0.125s ease-in-out 0s;
  -moz-transition: all 0.125s ease-in-out 0s;
  -ms-transition: all 0.125s ease-in-out 0s;
  -o-transition: all 0.125s ease-in-out 0s;
  transition: all 0.125s ease-in-out 0s;
}

.portfolio-item-preview {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
