h1,
h2,
h3,
h4,
h5,
h6 {
  font: 400 46px/1.2 $title-font-family;
  margin: 5px 0 5px;
  color: $title-color;
}

h1 {
  font-size: 64px;
  font-weight: 700;
  margin: 5px 0 15px;
}

h2 {
  font-size: 40px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 5px 0 15px;
}

h4 {
  font-size: 16px;
  margin: 5px 0 10px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 13px;
}
