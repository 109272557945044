@import '../variables';

@mixin button {
  font-family: $title-font-family;
  margin: 0px;
  padding: 10px 40px;
  border-radius: 2px;
  border-width: 0px;
  line-height: 24px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: $secondary-color;
  color: $primary-color;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease 0s;

  &:hover {
    background-color: $white-color;
  }
}
