@font-face {
  font-family: 'pabloibanez';
  src:  url('fonts/pabloibanez.eot?79j2kc');
  src:  url('fonts/pabloibanez.eot?79j2kc#iefix') format('embedded-opentype'),
    url('fonts/pabloibanez.ttf?79j2kc') format('truetype'),
    url('fonts/pabloibanez.woff?79j2kc') format('woff'),
    url('fonts/pabloibanez.svg?79j2kc#pabloibanez') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pabloibanez' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
  content: "\e905";
}
.icon-continuous:before {
  content: "\e903";
}
.icon-responsabilities:before {
  content: "\e900";
  color: #4d4d4d;
}
.icon-techstack:before {
  content: "\e901";
  color: #484848;
}
.icon-achievements:before {
  content: "\e902";
}
.icon-business:before {
  content: "\e906";
}
.icon-menu:before {
  content: "\e904";
}
.icon-location:before {
  content: "\e948";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-architecture:before {
  content: "\e995";
}
.icon-briefcase:before {
  content: "\e9ae";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-github:before {
  content: "\eab0";
}
.icon-linkedin-square:before {
  content: "\eac9";
}
.icon-linkedin:before {
  content: "\eaca";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-server:before {
  content: "\f233";
}
.icon-gitlab:before {
  content: "\f296";
}
