@import '../../../../assets/scss/mixins/section';
@import '../../../../assets/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../node_modules/bootstrap/scss/mixins/grid';

.resume {
  @include section;

  h2,
  h4,
  h5 {
    color: $primary-color;
  }

  &__row {
    @include make-row();
  }

  &__column-group {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }

    h3 {
      color: $primary-color;
    }
  }

  &__column-experiences {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(9);
    }
  }
}
