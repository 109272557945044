@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins/button';

@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

.simple-intro {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-color;

  &__center-container {
    text-align: center;
    padding: 0 15px;

    h1,
    h3 {
      color: $white-color;
    }

    h1 {
      margin-bottom: 30px;
    }
  }

  &__title-wrapper {
    margin-bottom: 100px;
  }

  &__button-wrapper {
    opacity: 0;

    &--shown {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  &__hello {
    font-weight: $font-weight-regular;
  }

  &__button {
    @include button;
  }

  &__roles {
    opacity: 0;

    span:not(:last-of-type) {
      margin-right: 15px;

      &::after {
        content: '|';
        margin-left: 15px;
      }
    }

    &--shown {
      opacity: 0.8;
      transition: opacity 0.5s;
    }
  }

  &__mouse-icon {
    position: absolute;
    bottom: 100px;

    > div {
      position: relative;
      border: 2px solid $white-color;
      border-radius: 16px;
      height: 50px;
      width: 30px;
      margin: 0 auto;
      display: block;

      > div {
        animation-name: drop;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-play-state: running;
        position: relative;
        border-radius: 10px;
        background: $white-color;
        width: 4px;
        height: 10px;
        top: 4px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
