$primary-color: #011e37;
$secondary-color: #02bba6;
$white-color: #ffffff;
$grey-light-color: #eee;
$body-color: #545454;
$title-color: #222;

$body-font-family: Raleway, serif, sans-serif;
$title-font-family: Montserrat, serif, sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Bootstrap Grid varibles

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 6;
