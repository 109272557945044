@import '../../../../assets/scss/mixins/nav';
@import '../../../../assets/scss/bootstrap';

.language-selector {
  position: relative;

  &--desktop {
    display: none;

    @include media-breakpoint-up(sm) {
      display: list-item;
    }
  }

  &--mobile {
    display: list-item;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  i {
    margin-left: 8px;
  }

  &__dropdown {
    position: fixed;
    opacity: 0.7;
    padding: 0;
    top: 66px;
    background-color: #000;
    border-radius: 4px;
    list-style-type: none;
    max-height: 0;
    overflow-y: hidden;
    height: auto;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

    &--shown {
      max-height: 500px;
      transition: max-height 0.3s ease-in-out;
    }

    &--fixed {
      top: 56px;
    }

    li {
      width: 100%;
      text-align: center;

      a {
        display: block;
        padding: 10px 36px !important;
      }
    }
  }
}
