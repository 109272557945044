@import '../../../assets/scss/variables';
@import '../../../assets/scss/bootstrap';

@mixin fixed {
  position: fixed;
  background: $primary-color;
  border-bottom: 2px solid $secondary-color;
  box-shadow: 0 0 4px rgba($secondary-color, 0.6);
  padding: 0;
}

.navbar {
  background: none;
  border-radius: 0;
  top: 0;
  border: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 20px 0;
  text-transform: uppercase;
  color: $white-color !important;
  transition: all 0.125s ease-in-out 0s;

  &--top {
    position: absolute;

    @include media-breakpoint-down(sm) {
      @include fixed();
    }
  }

  &--fixed {
    @include fixed();
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__header {
    font-family: $title-font-family;
    font-size: 16px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }

  &__toggle {
    position: relative;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    font-size: 30px;
    margin: 6px 0;
    padding-right: 0;
    border: 0;
    color: $white-color;

    &:focus {
      outline: none;
    }

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list-wrapper {
    width: 100%;
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

    @include media-breakpoint-up(sm) {
      max-height: 500px;
    }

    @include media-breakpoint-down(sm) {
      position: absolute;
      background-color: $primary-color;
      top: 2px;
      left: -20px;
      right: -20px;
      width: unset;
    }

    &--shown {
      max-height: 1000px;
      transition: max-height 0.3s ease-in-out;
    }
  }

  &__sections-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      margin: 15px 0;
    }

    li {
      &:not(:last-child) {
        @include media-breakpoint-down(sm) {
          border-bottom: 1px solid rgba($secondary-color, 0.2);
        }
      }

      a {
        padding: 12px 15px;
        font-size: 12px;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
          display: block;
          text-align: center;
        }
      }
    }
  }

  &__active-link {
    color: $secondary-color;
  }
}
