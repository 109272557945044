@import '../../../../assets/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../node_modules/bootstrap/scss/mixins/grid';
@import '../../../../assets/scss/mixins/button';
@import '../../../../assets/scss/mixins/section';

.contact {
  height: 850px;
  position: relative;
  font-family: $body-font-family;

  &__map-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $primary-color;
    z-index: 1;
    opacity: 0.6;
  }

  &__map {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &__content {
    @include section;
    height: 100%;
    width: 100%;
    z-index: 2;
    color: $white-color;

    h2,
    h4 {
      color: $white-color;
    }

    p {
      font-weight: $font-weight-bold;
      opacity: 0.8;
    }
  }

  &__row {
    @include make-row();
  }

  &__contact-methods {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    display: flex;
    flex-direction: column;
  }

  &__contact-method {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    i {
      font-size: 26px;
      margin-right: 15px;
    }

    span,
    a {
      font-weight: $font-weight-bold;
      color: $white-color;
    }
  }

  &__form-container {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }

  &__form {
  }

  &__form-input {
    margin-bottom: 20px;
  }

  &__form-input {
    background: #fff;
    border: 0;
    height: auto;
    font-family: $body-font-family;
    color: $primary-color;
    font-size: 14px;
    padding: 15px;
    border-radius: 3px;
    transition: all 0.125s ease-in-out 0s;
    width: 100%;
    line-height: 1.42857143;

    &:focus {
      outline: none;
    }

    &:focus,
    &:active {
      box-shadow: inset 0 0 2px 2px $secondary-color;
    }
  }

  &__form-button {
    @include button;
  }
}
