@import '../../assets/scss/variables';

.preloader {
  background: $white-color;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-in-out;

  &--fadeOut {
    opacity: 0;
    z-index: 0;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }
}
