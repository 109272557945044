@import '../../../../../assets/scss/variables';
@import '../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../../../node_modules/bootstrap/scss/mixins/grid';

.resume-element {
  @include make-row();

  h4,
  h5 {
    color: $primary-color;
  }

  h4 {
    margin-bottom: 15px !important;
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 0;
    border-top: 1px solid $grey-light-color;
  }

  i {
    margin-right: 10px;
    opacity: 0.8;
  }

  &__column-experience-description {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }

    h4 {
      font-weight: $font-weight-bold;
    }

    p,
    ul {
      margin: 5px 0;
    }

    p {
      padding-left: 25px;
    }
  }

  &__column-experience-place {
    @include make-col-ready();

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }

  &__experience-element {
    margin-bottom: 20px;

    ul {
      list-style-type: square;
      padding-left: 42px;

      li {
        margin-bottom: 5px;
      }
    }
  }

  &__secondary-elements {
    max-height: 0;
    overflow-y: hidden;
    height: auto;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

    &--shown {
      max-height: 600px;
      transition: max-height 0.3s ease-in-out;
    }
  }

  &__show-secondary-link {
    > div {
      display: flex;
      align-items: center;
    }

    &:hover {
      i {
        transform: translateX(2px);
      }
    }
  }

  &__expand-icon {
    margin-left: 8px;
    margin-top: 1px;
    display: inline-block;
  }
}
